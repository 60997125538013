import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DateTimeDisplay from "./session-parts/DateTimeDisplay";
import StatusCategories from "./session-parts/StatusCategories";
import ContextMenu from "./session-parts/ContextMenu";
import CaseButtons from "./session-parts/CaseButtons";
import SessionControls from "./session-parts/SessionControls";
import { supabase } from '../supabase'; 
import styles from './modules/Session.module.css';

export default function Session() {
  const { sessionId, room: greeklishRoom, courtType: greeklishCourtType, caseNumber } = useParams();
  const navigate = useNavigate();
  const [numButtons, setNumButtons] = useState(0);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [invalidatedNumbers, setInvalidatedNumbers] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [revalidatedNumber, setRevalidatedNumber] = useState(null);
  const [revalidationTimeoutId, setRevalidationTimeoutId] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [pausedNumbers, setPausedNumbers] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [contextMenuNumber, setContextMenuNumber] = useState(null);
  const [completedNumbers, setCompletedNumbers] = useState([]);
  const [dayCompleted, setDayCompleted] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [sessionStatus, setSessionStatus] = useState('active');
  
  const convertToGreek = (greeklishText) => {
    return greeklishText
      .replace('Aithousa-', 'ΑΙΘΟΥΣΑ ')
      .replace('Monomeles', 'ΜΟΝΟΜΕΛΕΣ')
      .replace('Trimeles', 'ΤΡΙΜΕΛΕΣ')
      .replace('Plimeleiodikeio', 'ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ')
      .replace('Efeteio', 'ΕΦΕΤΕΙΟ')
      .replace('Kakourgimatwn', 'ΚΑΚΟΥΡΓΗΜΑΤΩΝ')
      .replace('Plimmelimatwn', 'ΠΛΗΜΜΕΛΗΜΑΤΩΝ')
      .replace('Mikto-Orkoto-Dikastirio', 'ΜΙΚΤΟ ΟΡΚΩΤΟ ΔΙΚΑΣΤΗΡΙΟ')
      .replace('Miktro-Orkoto-Efeteio', 'ΜΙΚΤΟ ΟΡΚΩΤΟ ΕΦΕΤΕΙΟ')
      .replace(/-/g, ' ');
  };

  const room = convertToGreek(greeklishRoom);
  const courtType = convertToGreek(greeklishCourtType);
  // Fetch cases from the server(apo supabase)
  useEffect(() => {
    const fetchSessionData = async () => {
      if (!sessionId) {
        console.error('Δεν παρέχεται session-id.');
        return;
      }
      try {
        const { data: sessionData} = await supabase
          .from('sessions')
          .select('status')
          .eq('session_id', sessionId)
          .single();
        setSessionStatus(sessionData.status);
        setDayCompleted(sessionData.status === 'completed');

        const { data: casesData } = await supabase
          .from('cases')
          .select('*')
          .eq('session_id', sessionId);
       
        setNumButtons(casesData.length);
        setInvalidatedNumbers(casesData.filter(c => c.status === 'invalidated').map(c => c.case_number));
        setPausedNumbers(casesData.filter(c => c.status === 'paused').map(c => c.case_number));
        setCompletedNumbers(casesData.filter(c => c.status === 'completed').map(c => c.case_number));
        const activeCase = casesData.find(c => c.status === 'active');
        setSelectedNumber(activeCase ? activeCase.case_number : null);
      } catch (error) {
        console.error('Σφάλμα κατά τη φόρτωση δεδομένων του session:', error);
      }
    };
    fetchSessionData();
  }, [sessionId]);

  const [isLongPress, setIsLongPress] = useState(false);

  // 1second pressed click/mouse for contextMenu
  const handleMouseDown = (event, number) => {
    event.preventDefault();
    setIsLongPress(false);
    const id = setTimeout(() => {
      setIsLongPress(true);
      handleContextMenu(event, number);
    }, 1000);
    setTimeoutId(id);
  };

  // Clear timer
  const handleMouseUp = () => {
    clearTimeout(timeoutId);
    setTimeoutId(null);
  };

  //HANDLERS GIA TOUCH STO KINITO / TABLET
const handleTouchStart = (event, number) => {
  event.preventDefault();
  setIsLongPress(false);
  const id = setTimeout(() => {
    setIsLongPress(true);
    handleContextMenu(event, number);
  }, 1000);
  setTimeoutId(id);
};
const handleTouchEnd = () => {
  clearTimeout(timeoutId);
  setTimeoutId(null);
};
  // Handle a case number button
  const handleClick = async (number) => {
    if (isLongPress) {
      setIsLongPress(false);
      return;
    }
    
    if (selectedNumber !== number) {
      if (selectedNumber !== null) {
        if (
          !invalidatedNumbers.includes(selectedNumber) &&
          !pausedNumbers.includes(selectedNumber) &&
          !completedNumbers.includes(selectedNumber)
        ) {
          setCompletedNumbers([...completedNumbers, selectedNumber]);
          await updateCaseStatus(selectedNumber, 'completed');
        }
      }
      setSelectedNumber(number);
      await updateCaseStatus(number, 'active');
    }
  };
  // Update status in the Back-end
  const updateCaseStatus = async (caseNumber, status) => {
    if (!sessionId) {
      console.error('Session ID not provided.');
      return;
    }
    try {
      const { error } = await supabase
        .from('cases')
        .update({ status: status })
        .eq('case_number', caseNumber)
        .eq('session_id', sessionId);

      if (error) {
        console.error('Failed to update case status in the database:', error);
      }
    } catch (error) {
      console.error('Error updating case status:', error);
    }
  };
  // Reset the session and navigate back to the admin page (back-end)
  const handleReset = async () => {
    if (!sessionId) {
      console.error('Session ID not provided.');
      return;
    }
    try {
      const { error: updateRoomsError } = await supabase
        .from('rooms')
        .update({ current_session_id: null })
        .eq('current_session_id', sessionId);
      if (updateRoomsError) {
        console.error('Failed to update rooms table:', updateRoomsError);
        return;
      }
      const { error: deleteCasesError } = await supabase
        .from('cases')
        .delete()
        .eq('session_id', sessionId);
      if (deleteCasesError) {
        console.error('Failed to clear cases:', deleteCasesError);
        return;
      }
      const { error: deleteSessionError } = await supabase
        .from('sessions')
        .delete()
        .eq('session_id', sessionId);

      if (deleteSessionError) {
        console.error('Failed to delete session:', deleteSessionError);
        return;
      }
      navigate('/admin');
    } catch (error) {
      console.error('Error during reset:', error);
    }
};
const handleExit = async () => {
  if (!sessionId) {
    console.error('Δεν παρέχεται session-id.');
    return;
  }
  try {
    await supabase
      .from('cases')
      .delete()
      .eq('session_id', sessionId);
    await supabase
      .from('rooms')
      .update({ current_session_id: null })
      .eq('current_session_id', sessionId);
    await supabase
      .from('sessions')
      .delete()
      .eq('session_id', sessionId);
    navigate('/');
  } catch (error) {
    console.error('Σφάλμα κατά την έξοδο από τo session:', error);
  }
};
  // Handle invalidating / revalidating a case number
  const handleInvalidClick = (number) => {
    if (invalidatedNumbers.includes(number)) {
      setInvalidatedNumbers(invalidatedNumbers.filter((n) => n !== number));
      setRevalidatedNumber(number);
      const id = setTimeout(() => {
        setRevalidatedNumber(null);
        setRevalidationTimeoutId(null);
      }, 1000);
      setRevalidationTimeoutId(id);
    } else {
      setInvalidatedNumbers([...invalidatedNumbers, number]);
    }
  };
  // Display the context menu
  const handleContextMenu = (event, number) => {
    event.preventDefault();
    const rect = event.target.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const menuWidth = 200; 
  
    //GIA KINHTA <=600px
    let left;
    if (windowWidth <= 610) {
      left = Math.min(rect.left, windowWidth - menuWidth - 10);
  
      if (left < 50) {
        left = 50; // margin 50px
      }
    } 
    // tablets 600px - 1200px
    else if (windowWidth > 600 && windowWidth <= 1280) {
      left = rect.left + window.scrollX;
  
      // ean bgainei pali ektos oriwn apo deksia
      if (left + menuWidth > windowWidth) {
        left = windowWidth - menuWidth - 100; //den ypervenetai to menu apo deksia to noumero na allazw gia check
      }
    } 
    // megalyeres othones
    else {
      left = rect.left + window.scrollX;
  
      // Ean bgainei ektos oriwn apo deksia
      if (left + menuWidth > windowWidth) {
        left = rect.left - menuWidth - 10; // emfanizei to menu pros ta aristarae
      }
    }
  
    setContextMenu({
      top: rect.bottom + window.scrollY, // TO BAZEI KATW AKRIBWS APO TO CASEBUTTON
      left: left - 50, //edw na vazw ta swsta noumera poy voleyoyn
    });
    setContextMenuNumber(number);
  };
  
  // Click outside of menu closes contextMenu
  const handleClickOutside = useCallback((event) => {
    if (contextMenu && !event.target.closest('.context-menu')) {
      setContextMenu(null);
    }
  }, [contextMenu]);

  // Date & Time Update (realtime)
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      clearInterval(timer);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  // Handle context menu per case status
  const handleMenuOption = async (option) => { 
    if (contextMenuNumber !== null) {
      let newStatus = '';
      
      switch (option) {
        case 'PAUSE':
          setPausedNumbers(prev => [...prev, contextMenuNumber]);
          setCompletedNumbers(prev => prev.filter(n => n !== contextMenuNumber));
          if (selectedNumber === contextMenuNumber) {
            setSelectedNumber(contextMenuNumber);
          }
          newStatus = 'paused';
          break;
        case 'COMPLETE':
          setCompletedNumbers(prev => [...prev, contextMenuNumber]);
          setPausedNumbers(prev => prev.filter(n => n !== contextMenuNumber));
          if (selectedNumber === contextMenuNumber) {
            setSelectedNumber(null);
          }
          newStatus = 'completed';
          break;
        case 'RESUME':
          if (selectedNumber !== null) {
            await updateCaseStatus(selectedNumber, 'inactive');
          }
          setPausedNumbers(prev => prev.filter(n => n !== contextMenuNumber));
          setCompletedNumbers(prev => prev.filter(n => n !== contextMenuNumber));
          setSelectedNumber(contextMenuNumber);
          newStatus = 'active';
          break;
        case 'INVALIDATE':
          handleInvalidClick(contextMenuNumber);
          if (selectedNumber === contextMenuNumber) {
            setSelectedNumber(contextMenuNumber);
          }
          newStatus = 'invalidated';
          break;
        case 'CANCEL_INVALIDATION':
          setInvalidatedNumbers(prev => prev.filter(n => n !== contextMenuNumber));
          newStatus = 'inactive';
          break;
        case 'REVERT_COMPLETION':
          setCompletedNumbers(prev => prev.filter(n => n !== contextMenuNumber));
          newStatus = 'inactive';
          break;
        default:
          console.warn('Unknown menu option:', option);
      } 
      if (newStatus) {
        await updateCaseStatus(contextMenuNumber, newStatus);
      }
      setContextMenu(null);
    }
  };
  const handleCompleteDay = async () => {
    if (!sessionId) {
      console.error('Δεν παρέχεται session-id.');
      return;
    }
    try {
      if (selectedNumber !== null && !completedNumbers.includes(selectedNumber)) {
        await updateCaseStatus(selectedNumber, 'completed');
        setCompletedNumbers(prev => [...prev, selectedNumber]);
        setSelectedNumber(null);
      }
      await supabase
        .from('sessions')
        .update({ status: 'completed' })
        .eq('session_id', sessionId);

      setDayCompleted(true);
      setSessionStatus('completed');
    } catch (error) {
      console.error('Σφάλμα κατά την ολοκλήρωση της ενεργής υπόθεσης:', error);
    }
  };
  // BACK to /admin + reset
  const handleBackToAdmin = () => {
    handleReset();
    navigate("/admin");
  };
  const getCaseStatus = (caseNumber) => {
    if (invalidatedNumbers.includes(caseNumber)) return 'invalidated';
    if (pausedNumbers.includes(caseNumber)) return 'paused';
    return 'active';
  };
  return (
    <div className={styles.big_container}>
      <>
        <div className={styles.caseinfo}>
        <DateTimeDisplay 
              currentDateTime={currentDateTime} 
              state={{ sessionId, room, courtType, caseNumber }}
              selectedNumber={selectedNumber} 
              dayCompleted={dayCompleted} 
              caseStatus={getCaseStatus(selectedNumber)} 
          />
        </div>
        <div className={styles.infobox}>
          <div className={styles.wrapper}>
            <CaseButtons
              numButtons={numButtons}
              invalidatedNumbers={invalidatedNumbers}
              pausedNumbers={pausedNumbers}
              completedNumbers={completedNumbers}
              selectedNumber={selectedNumber}
              handleMouseDown={handleMouseDown} 
              handleMouseUp={handleMouseUp} 
              handleTouchStart={handleTouchStart}
              handleTouchEnd={handleTouchEnd} 
              handleClick={handleClick}
              revalidatedNumber={revalidatedNumber}
              revalidationTimeoutId={revalidationTimeoutId}
              dayCompleted={dayCompleted}
            />
            <StatusCategories />
            <SessionControls 
              dayCompleted={dayCompleted}
              handleCompleteDay={handleCompleteDay}
              handleExit={handleExit} 
              handleBackToAdmin={handleBackToAdmin}
            />
            <ContextMenu
              contextMenu={contextMenu}
              contextMenuNumber={contextMenuNumber}
              invalidatedNumbers={invalidatedNumbers}
              pausedNumbers={pausedNumbers}
              completedNumbers={completedNumbers}
              handleMenuOption={handleMenuOption}
              setContextMenu={setContextMenu}
            />
          </div>
          <hr className={styles.hrr}></hr>
          <div className={styles.footer}>
            Created by iWorx
          </div>
        </div>
      </>
    </div>
  );
}