import React from 'react';
import styles from '../modules/ContextMenu.module.css';

const ContextMenu = ({
  contextMenu,
  contextMenuNumber,
  handleMenuOption,
  invalidatedNumbers,
  pausedNumbers,
  completedNumbers,
  setContextMenu 
}) => {

  if (!contextMenu) return null;

  const isInvalidated = invalidatedNumbers.includes(contextMenuNumber);
  const isPaused = pausedNumbers.includes(contextMenuNumber);
  const isCompleted = completedNumbers.includes(contextMenuNumber);

  const handleOptionClick = (option) => {
    handleMenuOption(option);
    setContextMenu(null); 
  };

  return (
    <div
      className="context-menu"
      style={{
        top: contextMenu.top,
        left: contextMenu.left,
      }}
      id={styles.contextMenu}
    >
      {isInvalidated && (
        <button
          onClick={() => handleOptionClick('CANCEL_INVALIDATION')}
          className={styles.button}
        >
          <div className={`${styles.icon} ${styles.white}`}></div>
          ΑΚΥΡΩΣΗ ΑΝΑΒΟΛΗΣ
        </button>
      )}
      {isPaused && (
        <button
          onClick={() => handleOptionClick('RESUME')}
          className={styles.button}
        >
          <div className={`${styles.icon} ${styles.green}`}></div>
          ΤΕΛΟΣ ΠΑΥΣΗΣ
        </button>
      )}
      {isCompleted && (
        <button
          onClick={() => handleOptionClick('REVERT_COMPLETION')}
          className={styles.button}
        >
          <div className={`${styles.icon} ${styles.white}`}></div>
          <span className={styles.cancelsuccess}>ΑΚΥΡΩΣΗ ΟΛΟΚΛΗΡΩΣΗΣ</span>
        </button>
      )}
      {!isInvalidated && !isPaused && !isCompleted && (
        <>
          <button
            onClick={() => handleOptionClick('PAUSE')}
            className={styles.button}
          >
            <div className={`${styles.icon} ${styles.orange}`}></div>
            ΥΠΟΘΕΣΗ ΣΕ ΠΑΥΣΗ
          </button>
          <button
            onClick={() => handleOptionClick('INVALIDATE')}
            className={styles.button}
          >
            <div className={`${styles.icon} ${styles.red}`}></div>
            ΥΠΟΘΕΣΗ ΑΝΑΒΛΗΘΗΚΕ
          </button>
          <button
            onClick={() => handleOptionClick('COMPLETE')}
            className={styles.button}
          >
            <div className={`${styles.icon} ${styles.gray}`}></div>
            ΥΠΟΘΕΣΗ ΕΚΔΙΚΑΣΤΗΚΕ
          </button>
        </>
      )}
    </div>
  );
};

export default ContextMenu;