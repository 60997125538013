import React from 'react';
import styles from '../modules/ViewerDisplay.module.css';
import { useNavigate } from "react-router-dom";
const ViewerDisplay = ({ currentDateTime, state, selectedNumber, dayCompleted, caseStatus }) => {
  const navigate = useNavigate();
  const days = [
    "ΚΥΡΙΑΚΗ",
    "ΔΕΥΤΕΡΑ",
    "ΤΡΙΤΗ",
    "ΤΕΤΑΡΤΗ",
    "ΠΕΜΠΤΗ",
    "ΠΑΡΑΣΚΕΥΗ",
    "ΣΑΒΒΑΤΟ",
  ];

  function handleBackToLive(){
    navigate("/live-sessions");
      }
  const getCaseStatusHeader = () => {
    if (dayCompleted) {
      return (
        <div className={styles.center}>
          <h1 className={styles.noCasesHeader}>
            ΔΕΝ ΕΚΔΙΚΑΖΟΝΤΑΙ ΑΛΛΕΣ ΥΠΟΘΕΣΕΙΣ ΓΙΑ ΣΗΜΕΡΑ
          </h1>
        </div>
      );
    }

    if (selectedNumber > 0) {
      switch (caseStatus) {
        case 'paused':
          return <h1 className={styles.currentCaseHeader}>ΣΕ ΠΑΥΣΗ</h1>;
        case 'invalidated':
          return <h1 className={styles.currentCaseHeader}>ΣΕ ΑΝΑΒΟΛΗ</h1>;
        default:
          return <h1 className={styles.currentCaseHeader}>ΤΡΕΧΟΥΣΑ ΥΠΟΘΕΣΗ</h1>;
      }
    }

    return <hr className={styles.separator} />;
  };

  return (
      <div className={styles.container}>
            <div className={styles.iconbox}>
            <img src='/images/back-arrow.png' onClick={handleBackToLive} className={styles.backtoadminbtn} alt='back-nav-arrow'/>  
          </div>
          <h3 className={styles.dateTimeHeader}>
            {days[currentDateTime.getDay()]}, {currentDateTime.getDate()}-
            {currentDateTime.getMonth() + 1}-{currentDateTime.getFullYear()}
            {" / "}
            {currentDateTime.getHours()}:
            {String(currentDateTime.getMinutes()).padStart(2, "0")}:
            {String(currentDateTime.getSeconds()).padStart(2, "0")}
          </h3>
          <div className={styles.courtInfo}>
            <h2 className={styles.courtType}>{state?.courtType}</h2>
            <h3 className={styles.room}>{state?.room}</h3>
          </div>
          <div className={styles.currentcaseprogress}>
            {getCaseStatusHeader()}

                {!dayCompleted && (
                  <h1 className={styles.caseNumber}>
                    {selectedNumber}
                  </h1>
                )}
          </div>
      </div>
  );
};
export default ViewerDisplay;