import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminPage from "./components/AdminPage";
import Session from "./components/Session";
import Login from "./components/Login";
import Room from "./components/Room";
import LiveSessions from "./components/LiveSessions";
import Viewer from "./components/Viewer";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route 
            path="/session/:sessionId/:room/:courtType/:caseNumber" 
            element={<Session />} 
          />
          <Route path="/room/:roomNumber" element={<Room />} />
          <Route path="/room/viewer/:roomNumber" element={<Viewer />} />
          <Route path="/live-sessions" element={<LiveSessions />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;