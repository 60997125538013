import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import RoomDisplay from "./session-parts/ViewerDisplay";
import StatusCategories from "./session-parts/StatusCategories";
import CaseButtons from "./session-parts/CaseButtons";
import { supabase } from '../supabase'; 
import styles from './modules/Viewer.module.css';

export default function Viewer() {
  const { roomNumber } = useParams();
  const [numButtons, setNumButtons] = useState(0);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [invalidatedNumbers, setInvalidatedNumbers] = useState([]);
  const [pausedNumbers, setPausedNumbers] = useState([]);
  const [completedNumbers, setCompletedNumbers] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [dayCompleted, setDayCompleted] = useState(false);
  const [sessionInfo, setSessionInfo] = useState({ room: '', courtType: '', caseNumber: 0, sessionId: null, courtName: '' });
  const [isLoading, setIsLoading] = useState(true);

  const fetchRoomSession = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data: roomData} = await supabase
        .from('rooms')
        .select('current_session_id, room_name')
        .eq('room_name', `ΑΙΘΟΥΣΑ ${roomNumber}`)
        .single();
  
      if (roomData && roomData.current_session_id) {
        const { data: sessionData} = await supabase
          .from('sessions')
          .select('*')
          .eq('session_id', roomData.current_session_id)
          .single();
  
        if (sessionData) {
          const { data: casesData} = await supabase
            .from('cases')
            .select('*')
            .eq('session_id', sessionData.session_id);
          setSessionInfo({
            room: roomData.room_name, 
            courtType: casesData[0]?.court_type || '', 
            caseNumber: sessionData.case_number,
            sessionId: sessionData.session_id,
            courtName: sessionData.court_name 
          });
          setDayCompleted(sessionData.status === 'completed');
          setNumButtons(Math.max(casesData.length, 0));
          setInvalidatedNumbers(casesData.filter(c => c.status === 'invalidated').map(c => c.case_number));
          setPausedNumbers(casesData.filter(c => c.status === 'paused').map(c => c.case_number));
          setCompletedNumbers(casesData.filter(c => c.status === 'completed').map(c => c.case_number));
          setSelectedNumber(casesData.find(c => c.status === 'active')?.case_number || null);
          
        } else {          
          resetSessionInfo();
        }
      } else {      
        resetSessionInfo();
      }
    } catch (error) {
      console.error('Σφάλμα κατά τη λήψη του session:', error);
    } finally {
      setIsLoading(false);
    }
  }, [roomNumber]);

  const resetSessionInfo = () => {
    setSessionInfo({ room: '', courtType: '', caseNumber: 0, sessionId: null, courtName: '' });
    setDayCompleted(false);
    setNumButtons(0);
    setInvalidatedNumbers([]);
    setPausedNumbers([]);
    setCompletedNumbers([]);
    setSelectedNumber(null);
  };

  const checkSessionStatus = useCallback(async (sessionId) => {
    try {
      const { data: sessionData} = await supabase
        .from('sessions')
        .select('status')
        .eq('session_id', sessionId)
        .single();
      if (sessionData) {      
        setDayCompleted(sessionData.status === 'completed');
      }
    } catch (error) {
      console.error('Σφάλμα κατά τη λήψη της κατάστασης του session:', error);
    }
  }, []);

  const handleCaseUpdate = useCallback((updatedCase) => {
    
    if (updatedCase.session_id === sessionInfo.sessionId) {
      setNumButtons(prev => Math.max(prev, updatedCase.case_number));

      setInvalidatedNumbers(prev => 
        updatedCase.status === 'invalidated'
          ? [...new Set([...prev, updatedCase.case_number])]
          : prev.filter(num => num !== updatedCase.case_number)
      );

      setPausedNumbers(prev => 
        updatedCase.status === 'paused'
          ? [...new Set([...prev, updatedCase.case_number])]
          : prev.filter(num => num !== updatedCase.case_number)
      );

      setCompletedNumbers(prev => 
        updatedCase.status === 'completed'
          ? [...new Set([...prev, updatedCase.case_number])]
          : prev.filter(num => num !== updatedCase.case_number)
      );

      if (!dayCompleted) {
        setSelectedNumber(prevSelected => {
          if (updatedCase.status === 'active') {
            return updatedCase.case_number;
          } else if (prevSelected === updatedCase.case_number) {
            return prevSelected;
          }
          return prevSelected;
        });
      }
    }
  }, [sessionInfo.sessionId, dayCompleted]);

  const handleDayCompleted = useCallback((payload) => {
    if (payload.new && payload.new.room_name === `ΑΙΘΟΥΣΑ ${roomNumber}` && payload.new.status === 'completed') {
      setDayCompleted(true);
      setSelectedNumber(null);
      setSessionInfo(prev => ({ ...prev, status: 'completed' }));
    }
  }, [roomNumber]);

  useEffect(() => {
    fetchRoomSession();
    
    const subscription = supabase
      .channel('public:sessions')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'sessions' }, payload => {
        if (payload.eventType === 'INSERT') {       
          fetchRoomSession();
        } else if (payload.eventType === 'UPDATE' && payload.new.room_name === `ΑΙΘΟΥΣΑ ${roomNumber}`) {
          if (payload.new.status === 'completed') {
  
            handleDayCompleted(payload);
          } else {
            fetchRoomSession();
          }
        } else if (payload.eventType === 'DELETE' && payload.old.session_id === sessionInfo.sessionId) {
          resetSessionInfo();
        }
      })
      .on('postgres_changes', { event: '*', schema: 'public', table: 'cases' }, payload => {
        if (payload.new && payload.new.session_id === sessionInfo.sessionId) {         
          handleCaseUpdate(payload.new);
          checkSessionStatus(sessionInfo.sessionId); 
        }
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [roomNumber, fetchRoomSession, handleCaseUpdate, handleDayCompleted, sessionInfo.sessionId, checkSessionStatus]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const getCaseStatus = (caseNumber) => {
    if (invalidatedNumbers.includes(caseNumber)) return 'invalidated';
    if (pausedNumbers.includes(caseNumber)) return 'paused';
    return 'active';
  };

  return (
    <div className={styles.big_container}>
      {isLoading ? (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
      </div>
      ) : sessionInfo.sessionId ? (
        <>
          <div className={styles.caseinfo}>
            <RoomDisplay 
              currentDateTime={currentDateTime} 
              state={{ 
                courtType: sessionInfo.courtType, 
                room: sessionInfo.room,
                courtName: sessionInfo.courtName 
              }} 
              selectedNumber={selectedNumber} 
              dayCompleted={dayCompleted} 
              caseStatus={getCaseStatus(selectedNumber)} 
            />
          </div>
          <div className={styles.infobox}>
            <div className={styles.wrapper}>
              <CaseButtons
                numButtons={Math.max(numButtons, 0)}
                invalidatedNumbers={invalidatedNumbers}
                pausedNumbers={pausedNumbers}
                completedNumbers={completedNumbers}
                selectedNumber={selectedNumber}
                revalidatedNumber={null} 
                handleClick={() => {}} 
                handleMouseDown={() => {}}
                handleMouseUp={() => {}}
                handleTouchStart={() => {}}
                handleTouchEnd={() => {}}
                revalidationTimeoutId={null} 
                dayCompleted={dayCompleted}
              />
              <StatusCategories 
                room={sessionInfo.room} 
                courtType={sessionInfo.courtType} 
                caseNumber={sessionInfo.caseNumber}
              />
            </div>
            <hr className={styles.hrr}></hr>
            <div className={styles.footer}>
                <p className={styles.signature}>Created by iWorx</p>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.noactivesession}>
          <h2>Δεν υπάρχει ενεργή συνεδρία για αυτή την αίθουσα.</h2>
        </div>
      )}
    </div>
  );
}