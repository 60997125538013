import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './modules/Login.module.css';
import { signInUser } from '../supabase'; 

function Login() {
  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { user, error } = await signInUser(email, password); 
      if (error) {
        setError('Λανθασμένο email ή κωδικός πρόσβασης');
      } else {
        if (user && user.role === 'authenticated') {
          navigate('/admin'); 
        }
      }
    } catch (error) {
      console.error('Σφάλμα:', error);
      setError('Σφάλμα δικτύου. Ελέγξτε τη σύνδεσή σας.');
    }
  };
  
  return (
    <div className={styles.outerContainer}>
      <div className={styles.sidebar}>
        <img src="../images/Origin logo.svg" alt="icon" className={styles.sidebarIcon} />
        <div className={styles.sidebarText}>
          <div>ΔΙΚΗΓΟΡΙΚΟΣ</div>
          <div>ΣΥΛΛΟΓΟΣ</div>
          <div>ΗΡΑΚΛΕΙΟΥ</div>
        </div>
      </div>
      
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.header}>
            <img src="../images/dsh-icon.svg" alt="icon" className={styles.icon} />
            <h1 className={styles.title}>ΔΙΑΧΕΙΡΙΣΗ ΥΠΟΘΕΣΕΩΝ</h1>
          </div>
          <hr className={styles.hr} />
          <div className={styles.inputGroup}>
            <div className={styles.input_box}>
              <input
                id="email" 
                type="email" 
                value={email}
                onChange={handleEmailChange}
                className={styles.input}
                placeholder="Email Χρήστη"
              />
            </div>
            <div className={styles.input_box}>
              <input
                id="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className={styles.input}
                placeholder="Κωδικός Πρόσβασης"
              />
            </div>
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <button type="submit" className={styles.button}>ΕΙΣΟΔΟΣ</button>
        </form>
      </div>
    </div>
  );
}
export default Login;