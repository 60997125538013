import { createClient } from '@supabase/supabase-js'

const supabaseUrl = 'https://xkxtfibpmlyozlgirxve.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhreHRmaWJwbWx5b3psZ2lyeHZlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY1NjI3NTYsImV4cCI6MjA0MjEzODc1Nn0.fbnM735OEn16uhe3PPNPJkiKI0ck0s0uJx3GqVnz-cA';

export const supabase = createClient(supabaseUrl, supabaseKey)

export const signUpUser = async (email, password) => {
    const { user, error } = await supabase.auth.signUp({ email, password })
    return { user, error }
}

export const signInUser = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    return { user: data.user, error };
}

export const signOutUser = async () => {
    const { error } = await supabase.auth.signOut()
    return { error }
}

export const checkUserSession = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
        console.error('Error fetching user:', error);
        return null;
    }
    return user;
};