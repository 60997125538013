import React, { useState } from "react";
import Modal from "react-modal";
import styles from "../modules/Controls.module.css"

Modal.setAppElement('#root');

export default function SessionControls({
  dayCompleted,
  handleCompleteDay,
  handleExit,
  handleBackToAdmin
}) {
  const [isBackToAdminModalOpen, setIsBackToAdminModalOpen] = useState(false);
  const [isCompleteDayModalOpen, setIsCompleteDayModalOpen] = useState(false);

  const openBackToAdminModal = () => setIsBackToAdminModalOpen(true);
  const closeBackToAdminModal = () => setIsBackToAdminModalOpen(false);
  const openCompleteDayModal = () => setIsCompleteDayModalOpen(true);
  const closeCompleteDayModal = () => setIsCompleteDayModalOpen(false);

  const handleBackToAdminClick = () => {
    openBackToAdminModal();
  };
  const confirmBackToAdmin = () => {
    handleBackToAdmin();
    closeBackToAdminModal();
  };

  const handleCompleteDayClick = () => {
    openCompleteDayModal();
  };
  const confirmCompleteDay = () => {
    handleCompleteDay();
    closeCompleteDayModal();
  };

  return (
    <div className={styles.container}>
      <button
        onClick={dayCompleted ? handleExit : handleCompleteDayClick}
        className={`${styles.buttonBase} ${dayCompleted ? styles.buttonCompleteDayCompleted : styles.buttonComplete}`}
      >
        {dayCompleted ? "ΕΞΟΔΟΣ" : "ΟΛΟΚΛΗΡΩΣΗ ΗΜΕΡΑΣ"}
      </button> 
      {!dayCompleted && (
        <button
          onClick={handleBackToAdminClick}
          className={`${styles.buttonBase} ${styles.buttonBackToAdmin}`}
        >
         ΕΠΑΝΑΦΟΡΑ ΚΑΙ ΠΙΣΩ
        </button>
      )}
      <Modal
        isOpen={isBackToAdminModalOpen}
        onRequestClose={closeBackToAdminModal}
        className={styles.mod}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <h2 className={styles.modhead}>Ακύρωση συνεδρίας</h2>
        <p className={styles.mocancel}>Είστε σίγουρος/η ότι θέλετε να ακυρώσετε τη συνεδρία;</p>
        <button onClick={confirmBackToAdmin} className={styles.modalbtn}>
          Ναι
        </button>
        <button onClick={closeBackToAdminModal} className={styles.modalbtn}>
          Οχι
        </button>
      </Modal>
      <Modal
        isOpen={isCompleteDayModalOpen}
        onRequestClose={closeCompleteDayModal}
        className={styles.mod}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }
        }}
      >
        <h2 className={styles.modhead}>Ολοκλήρωση συνεδρίας</h2>
        <p className={styles.momessage}>Είστε σίγουρος/η ότι θέλετε να ολοκληρώσετε τη συνεδρία;</p>
        <button onClick={confirmCompleteDay} className={styles.modalbtn_complete}>
          Ναι
        </button>
        <button onClick={closeCompleteDayModal} className={styles.modalbtn_complete}>
          Οχι
        </button>
      </Modal>
    </div>
  );
}