  import React from 'react';
  import styles from '../modules/CaseButton.module.css'

  const CaseButtons = ({
    numButtons,
    invalidatedNumbers,
    pausedNumbers,
    completedNumbers,
    selectedNumber,
    revalidatedNumber,
    handleClick,
    handleMouseDown,
    handleMouseUp,
    handleTouchStart,
    handleTouchEnd, 
    revalidationTimeoutId,
    dayCompleted,
  }) => {
    const validNumButtons = Math.max(0, numButtons || 0);

    if (validNumButtons <= 0) {
      return null;
    }

    return (
      <div className={styles.container}>
        {[...Array(validNumButtons)].map((_, i) => {
          const number = i + 1;
          const isInvalid = invalidatedNumbers.includes(number);
          const isSelected = number === selectedNumber;
          const isRevalidating = number === revalidatedNumber && revalidationTimeoutId !== null;
          const isPaused = pausedNumbers.includes(number);
          const isCompleted = completedNumbers.includes(number);
          const isInactive = !isPaused && !isInvalid && !isCompleted && !isSelected;
          const isDisabled = dayCompleted;

          return (
            <button
              className={styles.btn}
              key={i}
              onMouseDown={(event) => handleMouseDown(event, number)} 
              onMouseUp={handleMouseUp} 
              onClick={() => {
                if (!isPaused && !isInvalid && !isCompleted && !isDisabled) {
                  handleClick(number);
                }
              }}
              onTouchStart={(event) => handleTouchStart(event, number)} 
              onTouchEnd={handleTouchEnd} 
              disabled={isDisabled}
              style={{
                backgroundColor: isRevalidating
                  ? "#FF7700"
                  : isInvalid
                  ? "#FF0000"
                  : isPaused
                  ? "#FF7700" 
                  : isCompleted
                  ? "#615f5f" 
                  : isSelected
                  ? "#008C46" 
                  : isInactive
                  ? "white"
                  : "initial",
                color: isPaused || isInvalid || isSelected || isCompleted ? "white" : "initial",
                userSelect: "none",
              }}
            >
              {number}
            </button>
          );
        })}
        <hr className={styles.hrr}/>
      </div>
    );
  };

  export default CaseButtons;