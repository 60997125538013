import React from 'react';
import styles from '../modules/StatusCategories.module.css';

export default function StatusCategories() {
    return (
        <>
            <div className={styles.big_container}>
                <div className={styles.wrapper}>
                    <div className={`${styles.border} ${styles.white}`}></div>
                    <p>ΥΠΟΘΕΣΗ ΓΙΑ ΕΚΔΙΚΑΣΗ</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.border} ${styles.green}`}></div>
                    <p>ΤΡΕΧΟΥΣΑ ΥΠΟΘΕΣΗ</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.border} ${styles.gray}`}></div>
                    <p>ΥΠΟΘΕΣΗ ΕΚΔΙΚΑΣΤΗΚΕ</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.border} ${styles.orange}`}></div>
                    <p>ΥΠΟΘΕΣΗ ΣΕ ΠΑΥΣΗ</p>
                </div>
                <div className={styles.wrapper}>
                    <div className={`${styles.border} ${styles.red}`}></div>
                    <p>ΥΠΟΘΕΣΗ ΑΝΑΒΛΗΘΗΚΕ</p>
                </div>
            </div>
        </>
    );
}