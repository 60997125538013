import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './modules/LiveSessions.module.css';
import { supabase } from '../supabase';

export default function LiveSessions() {
  const [currentCases, setCurrentCases] = useState({});
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [sessionsStatus, setSessionsStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); 

  //Update cases analoga to state tous
  const updateCases = useCallback((newCase) => {
    //an i ypothesi einai active emfanizetai ston pinaka
    if (newCase.status === 'active') {
      setCurrentCases(prevCurrentCases => ({
        ...prevCurrentCases,
        [newCase.court_type]: {
          case_number: newCase.case_number,
          status: newCase.status,
          court_type: newCase.court_type,
          sessionId: newCase.session_id,
        },
      }));
    } else {
      //alliws ginetai update sto state tou case
      setCurrentCases(prevCurrentCases => {
        if (
          prevCurrentCases[newCase.court_type] &&
          newCase.case_number === prevCurrentCases[newCase.court_type].case_number
        ) {
          return {
            ...prevCurrentCases,
            [newCase.court_type]: {
              ...prevCurrentCases[newCase.court_type],
              status: newCase.status,
            },
          };
        } else {
          return prevCurrentCases;
        }
      });
    }
  }, []);

  //pairnei ta update apo to sessions otan allazoyn ta dedomena tous 
  const updateSessions = useCallback((payload) => {
    if (payload.eventType === 'UPDATE') {
      const updatedSession = payload.new;

      setSessionsStatus(prev => ({
        ...prev,
        [updatedSession.session_id]: {
          ...prev[updatedSession.session_id],
          status: updatedSession.status,
        }
      }));

      //pairnei ta update otan ginetai completed to session apo to pinaka sessions
      if (updatedSession.status === 'completed') {
        setCurrentCases(prevCurrentCases => {
          const updatedCurrentCases = { ...prevCurrentCases };
          Object.keys(updatedCurrentCases).forEach(courtType => {
            const caseInfo = updatedCurrentCases[courtType];
            if (caseInfo && caseInfo.sessionId === updatedSession.session_id) {
              updatedCurrentCases[courtType] = {
                ...caseInfo,
                status: 'session_completed',
              };
            }
          });
          return updatedCurrentCases;
        });
      }
    } else if (payload.eventType === 'DELETE') {
      //otan kanei delete to session kanei delete ston pinaka twn sessions
      const deletedSessionId = payload.old.session_id;
      //kai afairesi tou session apo to sessionStatus
      setSessionsStatus(prev => {
        const updated = { ...prev };
        delete updated[deletedSessionId];
        return updated;
      });
      //diagrafi olwn twn cases poy sindeontai me ayto to session
      setCurrentCases(prevCurrentCases => {
        const updatedCurrentCases = { ...prevCurrentCases };
        Object.keys(updatedCurrentCases).forEach(courtType => {
          const caseInfo = updatedCurrentCases[courtType];
          if (caseInfo && caseInfo.sessionId === deletedSessionId) {
            delete updatedCurrentCases[courtType];
          }
        });
        return updatedCurrentCases;
      });
    }
  }, []);


  useEffect(() => {
    const fetchCases = async () => {
      setIsLoading(true);
      //Fortwsi dedomenwn sessions apo th vasi dedomenwn
      const { data: sessionsData, error: sessionsError } = await supabase
        .from('sessions')
        .select('session_id, status');

      if (sessionsError) {
        console.error('Σφάλμα κατά την ανάκτηση sessions:', sessionsError);
        return;
      }

      const sessionsStatusMap = {};

      for (const session of sessionsData) {
        sessionsStatusMap[session.session_id] = { status: session.status, room_name: '' };
        //gia kathe session anazitisi tou room_name apo ton pinaka rooms
        const { data: roomData, error: roomError } = await supabase
          .from('rooms')
          .select('room_name')
          .eq('current_session_id', session.session_id)
          .single();

        if (roomError) {
          console.error('Σφάλμα κατά την ανάκτηση room_name:', roomError);
          continue;
        }

        if (roomData) {
          sessionsStatusMap[session.session_id].room_name = roomData.room_name;
        }
      }

      setSessionsStatus(sessionsStatusMap);
      //anaktisi dedomenwn cases apo th vasi dedomenwn
      const { data: casesDataResult, error: casesError } = await supabase
        .from('cases')
        .select('case_number, court_type, status, session_id');

      if (casesError) {
        console.error('Σφάλμα κατά την ανάκτηση υποθέσεων:', casesError);
      } else {
        const initialCurrentCases = {};
        //eisagwgi twn active cases 
        casesDataResult.forEach(curr => {
          if (curr.status === 'active') {
            initialCurrentCases[curr.court_type] = {
              case_number: curr.case_number,
              status: curr.status,
              court_type: curr.court_type,
              sessionId: curr.session_id,
            };
          }
        });

        setCurrentCases(initialCurrentCases);
      }
      setIsLoading(false); 
    };

    fetchCases();

    //supabase gia kathe allagi poy ginetai sto pinaka cases
    const casesSubscription = supabase
      .channel('public:cases')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'cases' }, payload => {
        updateCases(payload.new);
      })
      .subscribe();

      //supabase gia kathe allagi poy ginetai sto pinaka sessions
    const sessionsSubscription = supabase
      .channel('public:sessions')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'sessions' }, payload => {
        updateSessions(payload);
      })
      .subscribe();

    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    //clearing ta subscriptions
    return () => {
      supabase.removeChannel(casesSubscription);
      supabase.removeChannel(sessionsSubscription);
      clearInterval(timer);
    };
  }, [updateCases, updateSessions]);

  // Mesw useEffect kanw tin anaktisi tou room_name alliws den to pianei swsta(AFORA TO NAVIGATE ayto to parakatw)
  useEffect(() => {
    Object.values(currentCases).forEach(currentCase => {
      if (currentCase) {
        const sessionId = currentCase.sessionId;
        const sessionInfo = sessionsStatus[sessionId];
        // Fetch room_name apo ton pinaka rooms,ean den exei anaktithei to pernei apo ti basi
        if (!sessionInfo || !sessionInfo.room_name) {
          (async () => {
            const { data: roomData, error: roomError } = await supabase
              .from('rooms')
              .select('room_name')
              .eq('current_session_id', sessionId)
              .single();

            if (roomError) {
              console.error('Σφάλμα κατά την ανάκτηση room_name:', roomError);
              return;
            }

            if (roomData) {
              setSessionsStatus(prev => ({
                ...prev,
                [sessionId]: {
                  ...prev[sessionId],
                  room_name: roomData.room_name,
                },
              }));
            }
          })();
        }
      }
    });
  }, [currentCases, sessionsStatus]);

  const formatDateTime = (date) => {
    const days = ['ΚΥΡΙΑΚΗ', 'ΔΕΥΤΕΡΑ', 'ΤΡΙΤΗ', 'ΤΕΤΑΡΤΗ', 'ΠΕΜΠΤΗ', 'ΠΑΡΑΣΚΕΥΗ', 'ΣΑΒΒΑΤΟ'];
    const day = days[date.getDay()];
    const dateStr = date.toLocaleDateString('el-GR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const timeStr = date.toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    return `${day}, ${dateStr} / ${timeStr}`;
  };

  //topothetisi twn css class sto div analoga to status
  const getBorderClass = (status) => {
    switch (status) {
      case 'active':
        return styles.activeBorder;
      case 'paused':
        return styles.pausedBorder;
      case 'invalidated':
        return styles.invalidatedBorder;
      case 'completed':
        return styles.completedBorder;
      case 'session_completed':
        return styles.completedBorder;
      default:
        return '';
    }
  };

  //Function gia navigation sto /room/:number
  const handleRoomClick = (currentCase) => {
    if (currentCase) {
      const sessionInfo = sessionsStatus[currentCase.sessionId];
      if (sessionInfo && sessionInfo.room_name) {
        //afairw to 'Aithousa' kai krataw mono to noumero gia na mpei px /room/1 
        const roomNumber = sessionInfo.room_name.replace('ΑΙΘΟΥΣΑ ', '');
        navigate(`/room/viewer/${roomNumber}`);
      }
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.spinner}></div>
        </div>
      ) : (
        <>
          <h1 className={styles.header}>ΗΜΕΡΗΣΙΑ ΠΙΝΑΚΙΑ</h1>
          <p className={styles.dateTime}>{formatDateTime(currentDateTime)}</p>
          <h3 className={styles.heading}>ΤΡΕΧΟΥΣΕΣ ΥΠΟΘΕΣΕΙΣ</h3>
          {Object.keys(currentCases).length > 0 ? (
           <div className={styles.bordersContainer}>
           {Object.values(currentCases).map((currentCase, index) => (
             <div
               key={index}
               className={`${styles.border} ${getBorderClass(currentCase.status)}`}
               onClick={() => handleRoomClick(currentCase)} 
               style={{ cursor: 'pointer' }} 
             >
               <h4 className={styles.courtType}>{currentCase.court_type}</h4>
               {currentCase.status === 'paused' && <p className={styles.statusText}>ΣΕ ΠΑΥΣΗ</p>}
               {currentCase.status === 'invalidated' && <p className={styles.statusText}>ΣΕ ΑΝΑΒΟΛΗ</p>}
               {currentCase.status === 'session_completed' ? (
                 <p className={styles.completedMessage}>ΟΛΟΚΛΗΡΩΣΗ ΗΜΕΡΑΣ</p>
               ) : (
                 <p className={styles.caseNumber}>{currentCase.case_number}</p>
               )}
             </div>
           ))}
         </div>
         
          ) : (
            <p className={styles.noCasesMessage}>Δεν υπάρχουν live συνεδρίες</p>
          )}
        </>
      )}
    </div>
  );
}